/* Importing Datepicker SCSS file. */
// @import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import "src/assets/scss/bs-datepicker.scss";

.nice-select {
  display: none !important;
}

:root {
  --fontFamily: 'Nunito', sans-serif;
  --mainColor: #ff5d22;
  --whiteColor: #ffffff;
  --blackColor: #221638;
  --optionalColor: #6b6b84;
  --fontSize: 17px;
  --transition: .5s;
}

body {
  padding: 0;
  margin: 0;

  font: {
    size: var(--fontSize);
    family: var(--fontFamily);
  }

  ;
}

.container {
  max-width: 1230px;
}

img {
  max-width: 100%;
  height: auto;
}

:focus {
  outline: 0 !important;
}

.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font: {
    family: var(--fontFamily);
    weight: 500;
  }

  ;
}

p {
  color: var(bs-body-color);
  line-height: 1.8;
  margin-bottom: 15px;

  font: {
    weight: 700;
    size: var(--fontSize);
  }

  ;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: var(--blackColor);
  text-decoration: none;
  transition: var(--transition);

  &:hover {
    color: var(--mainColor);
    text-decoration: none;
  }
}

.ptb-100 {
  padding: {
    top: 100px;
    bottom: 100px;
  }

  ;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.ptb-70 {
  padding: {
    top: 70px;
    bottom: 70px;
  }

  ;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.bg-e3fbff {
  background-color: #e3fbff;
}

.bg-f1f8fb {
  background-color: #f1f8fb;
}

.bg-fffbf5 {
  background-color: #fffbf5;
}

.bg-fafafb {
  background-color: #fafafb;
}

.bg-fffaf3 {
  background-color: #fffaf3;
}

/*default-btn*/
.default-btn {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  transition: var(--transition);
  border-radius: 5px;
  padding: 12px 25px 12px 50px;

  font: {
    weight: 700;
    size: 16px;
  }

  ;

  span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: var(--blackColor);
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 5px;
  }

  i {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);

    font: {
      weight: 700;
      size: 16.5px;
    }

    ;

    &.flaticon-user {
      margin-top: -1.5px;
    }
  }

  &:hover {
    color: var(--whiteColor);
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    transform: translateY(-5px);

    span {
      width: 225%;
      height: 562.5px;
    }
  }
}

/*section-title*/
.section-title {
  text-align: center;
  max-width: 700px;

  margin: {
    left: auto;
    right: auto;
    bottom: 55px;
  }

  ;

  .sub-title {
    position: relative;
    display: block;
    text-transform: uppercase;
    margin-bottom: 15px;
    color: var(--mainColor);

    font: {
      weight: 700;
      size: 15px;
    }

    ;

    img {
      margin-right: 5px;

      animation: {
        name: rotateme;
        duration: 40s;
        iteration-count: infinite;
        timing-function: linear;
      }

      ;
    }
  }

  h2 {
    margin-bottom: 0;
    position: relative;
    display: inline-block;

    font: {
      size: 44px;
    }

    ;

    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      background-color: var(--blackColor);
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  p {
    display: block;
    max-width: 600px;

    margin: {
      left: auto;
      right: auto;
      top: 12px;
    }

    ;
  }
}

/*form-control*/
.form-control {
  height: 50px;
  color: var(--blackColor);
  box-shadow: unset !important;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  transition: var(--transition);
  border-radius: 3px;
  padding: 1px 0 0 15px;

  font: {
    size: 16px;
    weight: 600;
  }

  ;

  &::placeholder {
    color: #7e7e7e;
    transition: var(--transition);
  }

  &:focus {
    border-color: var(--mainColor);
    background-color: transparent;

    &::placeholder {
      color: transparent;
    }
  }
}

textarea.form-control {
  height: auto;
  padding-top: 15px;
}

/*owl-carousel*/
.case-study-slides {
  &.owl-carousel {
    .owl-nav {
      margin: 0;

      .owl-prev,
      .owl-next {
        position: absolute;
        left: 100px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--optionalColor);
        transition: var(--transition);
        padding: 0;
        font-size: 45px;
        opacity: 0;
        visibility: hidden;
        background-color: transparent !important;
        line-height: 1;

        &:hover {
          color: var(--mainColor);
        }
      }

      .owl-next {
        left: auto;
        right: 100px;
      }
    }

    &:hover {
      .owl-nav {

        .owl-prev,
        .owl-next {
          left: 50px;
          opacity: 1;
          visibility: visible;
        }

        .owl-next {
          left: auto;
          right: 50px;
        }
      }
    }
  }
}

.testimonials-slides {
  &.owl-carousel {
    .owl-nav {
      margin: 0;

      .owl-prev,
      .owl-next {
        position: absolute;
        left: 0;
        width: 40px;
        top: -20px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--whiteColor) !important;
        color: var(--blackColor);
        border: 1px solid #eae2d7;
        line-height: 40px;
        transition: var(--transition);
        padding: 0;
        font-size: 16px;

        &:hover {
          background-color: var(--mainColor) !important;
          border-color: var(--mainColor);
          box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
          color: var(--whiteColor);
        }
      }

      .owl-next {
        left: auto;
        right: 0;
      }
    }
  }
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
  position: relative;
  z-index: 1;
  background: transparent url(assets/img/page-title-bg.jpg) right top no-repeat;

  padding: {
    top: 110px;
    bottom: 80px;
  }

  ;

  .shape-img1 {
    top: auto;
    bottom: 25%;
    left: 5%;
  }

  &.page-title-style-two {
    padding: {
      top: 120px;
      bottom: 120px;
    }

    ;
  }
}

.page-title-content {
  h2 {
    margin-bottom: 0;

    font: {
      size: 44px;
    }

    ;
  }

  ul {
    padding-left: 0;
    list-style-type: none;

    margin: {
      bottom: 0;
      top: 15px;
    }

    ;

    li {
      display: inline-block;
      margin-right: 25px;
      position: relative;

      font: {
        size: 16.5px;
        weight: 700;
      }

      ;

      a {
        display: inline-block;
      }

      &:last-child {
        margin-right: 0;

        &::before {
          display: none;
        }
      }

      &::before {
        color: var(--mainColor);
        position: absolute;
        right: -18px;
        top: 5.5px;
        line-height: 1;
        content: "\f104";

        font: {
          family: Flaticon;
          size: 11px;
        }

        ;
      }
    }
  }

  &.text-center {
    ul {
      li {
        margin: {
          left: 13px;
          right: 13px;
        }

        ;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

/*================================================
Shape Img CSS
=================================================*/
.shape-img1 {
  position: absolute;
  left: 10%;
  top: 2%;
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 10s;
      iteration-count: infinite;
      timing-function: linear;
    }

    ;
  }
}

.shape-img2 {
  position: absolute;
  bottom: 10%;
  z-index: -1;
  right: 30px;
  animation: rotate3d 4s linear infinite;
  opacity: .25;
}

.shape-img3 {
  position: absolute;
  left: 20%;
  top: 5%;
  z-index: -1;
  animation: movebounce 5s linear infinite;
  opacity: .15;
}

@keyframes rotate3d {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.footer-map {
  position: absolute;
  right: 20%;
  top: 40%;
  transform: translateY(-40%);
  z-index: -1;
  opacity: .8;

  img {
    animation: moveleftbounce 5s linear infinite;
  }
}

@keyframes moveleftbounce {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes animeCircleSpin {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(720deg);
  }

  100% {
    transform: rotate(0);
  }
}

.circle-shape2 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.vector-shape7 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.vector-shape8 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.circle-shape1 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 100%;
  z-index: -1;

  .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: #e5ebf0;
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 15vh;
      width: 100%;
      top: -50%;
      left: 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, var(--mainColor) 75%, var(--mainColor) 100%);
      animation: run 7s 0s infinite;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    }

    &:nth-child(1) {
      margin-left: -30%;

      &::after {
        animation-delay: 2s;
      }
    }

    &:nth-child(3) {
      margin-left: 30%;

      &::after {
        animation-delay: 2.5s;
      }
    }

    &:nth-child(4) {
      margin-left: -15%;

      &::after {
        animation-delay: 3s;
      }
    }

    &:nth-child(5) {
      margin-left: 15%;

      &::after {
        animation-delay: 3.5s;
      }
    }
  }
}

@keyframes run {
  0% {
    top: -50%;
  }

  100% {
    top: 110%;
  }
}

.vector-shape4 {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;

  img {
    animation: movebounce 5s linear infinite;
  }
}

.vector-shape5 {
  position: absolute;
  left: 15%;
  bottom: -7%;
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 350s;
      iteration-count: infinite;
      timing-function: linear;
    }

    ;
  }
}

.map-shape1 {
  position: absolute;
  right: 15%;
  top: 0;
  z-index: -1;
  opacity: 0.5;

  img {
    animation: {
      name: rotatemetwo;
      duration: 300s;
      iteration-count: infinite;
      timing-function: linear;
    }

    ;
  }
}

@keyframes movebounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

.shape-img1 {
  position: absolute;
  left: 10%;
  top: 2%;
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 10s;
      iteration-count: infinite;
      timing-function: linear;
    }

    ;
  }
}

.divider {
  width: 100%;
  position: absolute;
  height: 100px;
  pointer-events: none;
  -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  -webkit-mask-size: 100% 101%;
  mask-size: 100% 101%;
  background: #fffaf3;
  bottom: 0;
  left: 0;
  z-index: -1;

  &.white-color {
    background-color: var(--whiteColor);
  }
}

.vector-shape1 {
  position: absolute;
  left: 12%;
  z-index: -1;
  bottom: 40px;

  img {
    animation: {
      name: rotatemetwo;
      duration: 350s;
      iteration-count: infinite;
      timing-function: linear;
    }

    ;
  }
}

.vector-shape2 {
  position: absolute;
  right: 10%;
  top: 230px;
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 350s;
      iteration-count: infinite;
      timing-function: linear;
    }

    ;
  }
}

@keyframes rotatemetwo {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.vector-shape3 {
  position: absolute;
  right: 0;
  bottom: 50px;
  z-index: -1;
}

.vector-shape9 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.vector-shape10 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.vector-shape6 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: .70;

  img {
    animation: movebounce 5s linear infinite;
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.75);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.vector-shape6 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: .70;

  img {
    animation: movebounce 5s linear infinite;
  }
}

.vector-shape11 {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;

  img {
    animation: movebounce 5s linear infinite;
  }
}

.map-shape2 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 200s;
      iteration-count: infinite;
      timing-function: linear;
    }

    ;
  }
}

.scientist-area {
  position: relative;
  z-index: 1;

  &.bg-color {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
      right: 0;
      background-color: #f1f8fb;
      height: 247px;
    }
  }
}

.process-area {
  position: relative;
  z-index: 1;
}

.pagination-area {
  margin-top: 35px;

  .page-numbers {
    width: 40px;
    height: 40px;
    background-color: var(--whiteColor);
    box-shadow: 0 0px 30px 5px #e4e4ee;
    color: var(--blackColor);
    text-align: center;
    display: inline-block;
    border-radius: 3px;
    line-height: 41px;
    position: relative;

    margin: {
      left: 5px;
      right: 5px;
    }

    ;

    font: {
      size: var(--fontSize);
      weight: 800;
    }

    ;

    &:hover,
    &.current {
      color: var(--whiteColor);
      background-color: var(--mainColor);
    }

    i {
      position: relative;
      top: 1.5px;
    }
  }
}

.widget-area {
  padding-left: 15px;

  &.widget-left-sidebar {
    padding: {
      right: 15px;
      left: 0;
    }

    ;
  }

  .widget {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    .widget-title {
      margin-bottom: 25px;
      position: relative;
      padding-bottom: 7px;
      border-bottom: 1px solid #eeeeee;

      font: {
        size: 22px;
      }

      ;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 50px;
        height: 1px;
        bottom: -1px;
        background-color: var(--mainColor);
      }
    }
  }

  .widget_search {
    form {
      position: relative;

      .screen-reader-text {
        display: none;
      }

      label {
        display: block;
        margin-bottom: 0;
      }

      .search-field {
        height: 50px;
        color: var(--blackColor);
        background-color: #f2f4f5;
        display: block;
        width: 100%;
        border-radius: 5px;
        padding: 0 0 0 15px;
        border: none;
        transition: var(--transition);

        font: {
          size: var(--fontSize);
          weight: 500;
        }

        ;

        &::placeholder {
          color: var(--optionalColor);
          transition: var(--transition);
        }

        &:focus {
          &::placeholder {
            color: transparent;
          }
        }
      }

      button {
        border: none;
        background-color: transparent;
        color: var(--mainColor);
        height: 40px;
        width: 40px;
        position: absolute;
        right: 5px;
        padding: 0;
        transition: var(--transition);
        top: 5px;
        font-size: 20px;

        i {
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover,
        &:focus {
          border-radius: 5px;
          background-color: var(--mainColor);
          color: var(--whiteColor);
        }
      }
    }
  }

  .widget_tracer_posts_thumb {
    position: relative;
    overflow: hidden;

    .item {
      overflow: hidden;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      .thumb {
        float: left;
        height: 80px;
        overflow: hidden;
        display: block;
        position: relative;
        width: 80px;
        margin-right: 15px;
        z-index: 1;

        .fullimage {
          width: 80px;
          height: 80px;
          display: inline-block;
          border-radius: 5px;

          background: {
            size: cover !important;
            repeat: no-repeat;
            position: center center !important;
          }

          ;

          &.bg1 {
            background-image: url(assets/img/blog/img9.jpg);
          }

          &.bg2 {
            background-image: url(assets/img/blog/img8.jpg);
          }

          &.bg3 {
            background-image: url(assets/img/blog/img7.jpg);
          }
        }

        &::before,
        &::after {
          transition: all .8s cubic-bezier(.2, 1, .22, 1);
          content: '';
          background-color: var(--whiteColor);
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
        }

        &::before {
          width: 40px;
          height: 1px;
          left: 100%;
        }

        &::after {
          height: 40px;
          width: 1px;
          top: 0;
        }
      }

      .info {
        overflow: hidden;
        margin-top: 5px;

        span {
          display: block;
          color: var(--optionalColor);
          text-transform: uppercase;

          margin: {
            top: -2px;
            bottom: 5px;
          }

          ;

          font: {
            size: 13px;
          }

          ;
        }

        .title {
          margin-bottom: 0;
          line-height: 1.4;

          font: {
            size: 17px;
            weight: 800;
          }

          ;

          a {
            display: inline-block;
          }
        }
      }

      &:hover {
        .thumb {

          &::before,
          &::after {
            opacity: 1;
            top: 50%;
            left: 50%;
          }
        }
      }
    }
  }

  .widget_categories {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 12px;
        overflow: hidden;
        color: var(--blackColor);
        padding-left: 18px;

        font: {
          weight: 700;
          size: 15px;
        }

        ;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          background: var(--mainColor);
          height: 8px;
          width: 8px;
          content: '';
          border-radius: 50%;
          left: 0;
          top: 7px;
          position: absolute;
        }

        a {
          color: var(--blackColor);
          display: inline-block;

          &:hover {
            color: var(--mainColor);
          }
        }

        .post-count {
          font-size: 14px;
          display: inline-block;
          position: relative;
          top: 1px;
        }
      }
    }
  }

  .widget_tag_cloud {
    .widget-title {
      margin-bottom: 17px;
    }
  }

  .tagcloud {
    a {
      display: inline-block;
      background: #f5f5f5;
      color: var(--blackColor);
      padding: 7px 15px;
      border: none;
      border-radius: 3px;

      font: {
        weight: 700;
        size: 14px !important;
      }

      ;

      margin: {
        top: 8px;
        right: 4px;
      }

      ;

      &:hover,
      &:focus {
        color: var(--whiteColor);
        background-color: var(--mainColor);
      }
    }
  }

  .widget_popular_products {
    position: relative;
    overflow: hidden;

    .widget-title {
      margin-bottom: 30px;
    }

    .item {
      overflow: hidden;
      margin-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }

      .thumb {
        float: left;
        height: 110px;
        overflow: hidden;
        display: block;
        position: relative;
        width: 110px;
        margin-right: 15px;
        z-index: 1;

        .fullimage {
          width: 110px;
          height: 110px;
          display: inline-block;
          border-radius: 3px;

          background: {
            size: cover !important;
            repeat: no-repeat;
            position: center center !important;
          }

          ;

          &.bg1 {
            background-image: url(assets/img/products/img7.jpg);
          }

          &.bg2 {
            background-image: url(assets/img/products/img8.jpg);
          }

          &.bg3 {
            background-image: url(assets/img/products/img8.jpg);
          }
        }

        &::before,
        &::after {
          transition: all .8s cubic-bezier(.2, 1, .22, 1);
          content: '';
          background-color: var(--whiteColor);
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
        }

        &::before {
          width: 40px;
          height: 1px;
          left: 100%;
        }

        &::after {
          height: 40px;
          width: 1px;
          top: 0;
        }
      }

      .info {
        overflow: hidden;
        margin-top: 5px;

        span {
          display: block;
          color: var(--mainColor);
          text-transform: uppercase;

          margin: {
            bottom: 6px;
            top: -5px;
          }

          ;

          font: {
            size: 18px;
            weight: 800;
            style: italic;
          }

          ;
        }

        .title {
          margin-bottom: 0;
          line-height: 1.4;

          font: {
            size: 17px;
            weight: 800;
          }

          ;

          a {
            display: inline-block;
          }
        }

        .rating {
          margin-top: 8px;

          i {
            color: #f6b500;
            display: inline-block;
            margin-right: -2px;
          }
        }
      }

      &:hover {
        .thumb {

          &::before,
          &::after {
            opacity: 1;
            top: 50%;
            left: 50%;
          }
        }
      }
    }
  }

  .filter_by_price {
    .irs--flat {
      .irs-bar {
        background-color: var(--mainColor);
      }

      .irs-handle>i:first-child {
        background-color: var(--mainColor);
      }
    }

    .irs--flat .irs-from,
    .irs--flat .irs-to,
    .irs--flat .irs-single {
      background-color: var(--mainColor);
      color: var(--whiteColor);
    }
  }

  .widget_recent_courses {
    position: relative;
    overflow: hidden;

    .widget-title {
      margin-bottom: 30px;
    }

    .item {
      overflow: hidden;
      margin-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }

      .thumb {
        float: left;
        height: 80px;
        overflow: hidden;
        display: block;
        position: relative;
        width: 80px;
        margin-right: 15px;
        z-index: 1;

        .fullimage {
          width: 80px;
          height: 80px;
          display: inline-block;
          border-radius: 3px;

          background: {
            size: cover !important;
            repeat: no-repeat;
            position: center center !important;
          }

          ;

          &.bg1 {
            background-image: url(assets/img/courses/img1.jpg);
          }

          &.bg2 {
            background-image: url(assets/img/courses/img2.jpg);
          }

          &.bg3 {
            background-image: url(assets/img/courses/img3.jpg);
          }
        }

        &::before,
        &::after {
          transition: all .8s cubic-bezier(.2, 1, .22, 1);
          content: '';
          background-color: var(--whiteColor);
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
        }

        &::before {
          width: 40px;
          height: 1px;
          left: 100%;
        }

        &::after {
          height: 40px;
          width: 1px;
          top: 0;
        }
      }

      .info {
        overflow: hidden;
        margin-top: 5px;

        span {
          display: block;
          color: var(--mainColor);
          text-transform: uppercase;

          margin: {
            bottom: 6px;
            top: -5px;
          }

          ;

          font: {
            size: 18px;
            weight: 800;
            style: italic;
          }

          ;
        }

        .title {
          margin-bottom: 0;
          line-height: 1.4;

          font: {
            size: 17px;
            weight: 800;
          }

          ;

          a {
            display: inline-block;
          }
        }
      }

      &:hover {
        .thumb {

          &::before,
          &::after {
            opacity: 1;
            top: 50%;
            left: 50%;
          }
        }
      }
    }
  }

  .widget_insight {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        margin-bottom: -1px;

        a {
          position: relative;
          display: block;
          padding: 15px 25px 15px 40px;
          color: var(--optionalColor);
          border: 1px solid #eeeeee;

          font: {
            size: var(--fontSize);
            weight: 700;
          }

          ;

          &::before {
            width: 5px;
            height: 5px;
            transition: var(--transition);
            background-color: var(--optionalColor);
            content: '';
            position: absolute;
            left: 25px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
          }

          &:hover,
          &.active {
            border-color: var(--mainColor);
            background-color: var(--mainColor);
            color: var(--whiteColor);

            &::before {
              background-color: var(--whiteColor);
            }
          }
        }

        &.active {
          a {
            border-color: var(--mainColor);
            background-color: var(--mainColor);
            color: var(--whiteColor);

            &::before {
              background-color: var(--whiteColor);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {

  body {
    font-size: 15px;
  }

  p {
    font-size: 15px;
  }

  .container {
    max-width: 100%;
  }

  .ptb-100 {
    padding: {
      top: 60px;
      bottom: 60px;
    }

    ;
  }

  .pt-100 {
    padding-top: 60px;
  }

  .pb-100 {
    padding-bottom: 60px;
  }

  .ptb-70 {
    padding: {
      top: 30px;
      bottom: 30px;
    }

    ;
  }

  .pt-70 {
    padding-top: 30px;
  }

  .pb-70 {
    padding-bottom: 30px;
  }

  .default-btn {
    padding: 11px 20px 11px 40px;
    font-size: 15px;

    i {
      left: 19px;
      font-size: 14px;
    }
  }

  .section-title {
    max-width: 100%;

    margin: {
      left: 0;
      right: 0;
      bottom: 40px;
    }

    ;

    .sub-title {
      margin-bottom: 10px;
      font-size: 14px;
    }

    h2 {
      font-size: 27px;
    }

    p {
      max-width: 100%;

      margin: {
        left: 0;
        right: 0;
        top: 10px;
      }

      ;
    }
  }

  .form-control {
    height: 45px;
    font-size: 14.5px;
  }

  .lines {
    display: none;
  }

  .vector-shape1,
  .vector-shape2,
  .vector-shape3,
  .vector-shape4,
  .vector-shape5,
  .vector-shape6,
  .vector-shape7,
  .vector-shape8,
  .vector-shape9,
  .vector-shape10,
  .vector-shape11,
  .vector-shape12,
  .circle-shape1,
  .circle-shape2,
  .circle-shape3,
  .circle-shape4,
  .map-shape1,
  .map-shape2 {
    display: none !important;
  }

  .case-study-slides {
    &.owl-carousel {
      .owl-nav {

        .owl-prev,
        .owl-next {
          left: 15px !important;
          font-size: 30px;
          top: auto;
          transform: unset;
          bottom: 20px;
          opacity: 1;
          visibility: visible;

          &:hover {
            color: var(--mainColor);
          }
        }

        .owl-next {
          left: auto !important;
          right: 15px !important;
        }
      }
    }
  }

  .divider {
    display: none;
  }

  .testimonials-slides {
    &.owl-carousel {
      margin-bottom: 30px;

      .owl-nav {
        text-align: center;

        .owl-prev,
        .owl-next {
          position: relative;
          left: 0 !important;
          top: 0;
          transform: unset;
          right: 0 !important;

          margin: {
            left: 5px;
            right: 5px;
          }

          ;
        }
      }
    }
  }

  .footer-map {
    display: none;
  }

  .page-title-area {
    background-position: center center;

    padding: {
      top: 150px;
      bottom: 60px;
    }

    ;

    &.page-title-style-two {
      padding: {
        top: 60px;
        bottom: 60px;
      }

      ;
    }
  }

  .page-title-content {
    text-align: center;

    h2 {
      font-size: 28px;
    }

    ul {
      margin-top: 12px;

      li {
        font-size: 14.5px;

        margin: {
          left: 13px;
          right: 13px;
        }

        ;

        &:first-child {
          margin-left: 0;
        }

        &::before {
          top: 4.5px;
          font-size: 10px;
        }
      }
    }
  }

  .shape-img1,
  .shape-img2,
  .shape-img3 {
    display: none;
  }

  .pagination-area {
    margin-top: 20px;

    .page-numbers {
      width: 35px;
      height: 35px;
      line-height: 37px;
      font-size: 15px;
    }
  }

  .widget-area {
    padding-left: 0;
    margin-top: 40px;

    .widget {
      .widget-title {
        font-size: 19px;
      }
    }

    .widget_recent_courses {
      .item {
        .info {
          span {
            font-size: 15px;
          }

          .title {
            font-size: 15px;
          }
        }
      }
    }

    .widget_popular_products {
      .item {
        .info {
          span {
            font-size: 15px;
          }

          .title {
            font-size: 15px;
          }
        }
      }
    }

    .widget_insight {
      ul {
        li {
          a {
            font-size: 15px;
          }
        }
      }
    }

    .widget_tracer_posts_thumb {
      .item {
        .info {
          .title {
            font-size: 15px;
          }
        }
      }
    }

    &.widget-left-sidebar {
      padding: {
        right: 0;
      }

      ;

      padding: {
        top: 0;
        bottom: 40px;
      }

      ;
    }
  }

}

@media only screen and (min-width: 576px) and (max-width: 767px) {

  .container {
    max-width: 540px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  body {
    font-size: 16px;
  }

  p {
    font-size: 16px;
  }

  .container {
    max-width: 720px;
  }

  .ptb-100 {
    padding: {
      top: 80px;
      bottom: 80px;
    }

    ;
  }

  .pt-100 {
    padding-top: 80px;
  }

  .pb-100 {
    padding-bottom: 80px;
  }

  .ptb-70 {
    padding: {
      top: 50px;
      bottom: 50px;
    }

    ;
  }

  .pt-70 {
    padding-top: 50px;
  }

  .pb-70 {
    padding-bottom: 50px;
  }

  .section-title {
    max-width: 600px;
    margin-bottom: 45px;

    .sub-title {
      font-size: 14px;
    }

    h2 {
      font-size: 35px;
    }

    p {
      max-width: 500px;
    }
  }

  .default-btn {
    font-size: 15px;
  }

  .vector-shape1,
  .vector-shape2,
  .vector-shape3,
  .vector-shape4,
  .vector-shape5,
  .vector-shape6,
  .vector-shape7,
  .vector-shape8,
  .vector-shape9,
  .vector-shape10,
  .vector-shape11,
  .vector-shape12,
  .circle-shape1,
  .circle-shape2,
  .circle-shape3,
  .circle-shape4,
  .map-shape1,
  .map-shape2 {
    display: none !important;
  }

  .footer-map {
    right: auto;
    left: 50%;
    transform: translateX(-50%) translateY(-40%);
  }

  .vector-shape4,
  .vector-shape5,
  .map-shape1 {
    display: none;
  }

  .divider {
    display: none;
  }

  .testimonials-slides {
    &.owl-carousel {
      margin-bottom: 30px;

      .owl-nav {
        text-align: center;

        .owl-prev,
        .owl-next {
          position: relative;
          left: 0 !important;
          top: 0;
          transform: unset;
          right: 0 !important;

          margin: {
            left: 5px;
            right: 5px;
          }

          ;
        }
      }
    }
  }

  .vector-shape1,
  .vector-shape2,
  .vector-shape3 {
    display: none;
  }

  .vector-shape6 {
    display: none;
  }

  .vector-shape11 {
    display: none;
  }

  .page-title-area {
    background-position: center center;

    padding: {
      bottom: 80px;
      top: 160px;
    }

    ;

    &.page-title-style-two {
      padding: {
        top: 80px;
        bottom: 80px;
      }

      ;
    }
  }

  .page-title-content {
    text-align: center;

    h2 {
      font: {
        size: 38px;
      }

      ;
    }

    ul {
      li {
        margin: {
          left: 10px;
          right: 15px;
        }

        ;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .widget-area {
    padding-left: 0;
    margin-top: 35px;

    &.widget-left-sidebar {
      padding-right: 0;

      margin: {
        top: 0;
        bottom: 35px;
      }

      ;
    }
  }

}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

  body {
    font-size: 16px;
  }

  p {
    font-size: 16px;
  }

  .container {
    max-width: 960px;
  }

  .section-title {
    max-width: 700px;
    margin-bottom: 50px;

    h2 {
      font-size: 36px;
    }
  }

  .default-btn {
    font-size: 15px;
  }

  .footer-map {
    right: auto;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    opacity: 0.55;
  }

  .vector-shape3 {
    display: none !important;
  }

  .vector-shape7 {
    img {
      max-width: 60%;
    }
  }

  .vector-shape8 {
    display: none !important;
  }

  .vector-shape6 {
    display: none !important;
  }

  .circle-shape1,
  .circle-shape2,
  .lines {
    display: none !important;
  }

  .case-study-slides {
    &.owl-carousel {
      .owl-nav {
        position: absolute;
        right: 25px;
        top: 30px;

        .owl-prev,
        .owl-next {
          position: relative;
          left: 0 !important;
          top: 0;
          transform: translateY(0);
          margin-left: 5px;
          font-size: 25px;
          opacity: 1;
          visibility: visible;
        }

        .owl-next {
          right: 0 !important;
        }
      }
    }
  }

  .vector-shape4,
  .vector-shape5 {
    display: none !important;
  }

  .testimonials-slides {
    &.owl-carousel {
      margin-bottom: 30px;

      .owl-nav {
        text-align: center;

        .owl-prev,
        .owl-next {
          position: relative;
          left: 0 !important;
          top: 0;
          transform: unset;
          right: 0 !important;

          margin: {
            left: 5px;
            right: 5px;
          }

          ;
        }
      }
    }
  }

  .divider {
    height: 70px;
  }

  .vector-shape1,
  .vector-shape2 {
    display: none !important;
  }

  .page-title-area {
    padding: {
      top: 160px;
      bottom: 80px;
    }

    ;

    &.page-title-style-two {
      padding: {
        bottom: 90px;
        top: 80px;
      }

      ;
    }
  }

  .page-title-content {
    text-align: center;

    h2 {
      font-size: 40px;
    }

    ul {
      li {
        margin: {
          left: 14px;
          right: 14px;
        }

        ;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .widget-area {
    padding-left: 0;
  }

}

@media only screen and (min-width: 1200px) and (max-width: 1355px) {

  .container {
    max-width: 1140px;
  }

  .circle-shape1 {
    display: none;
  }

}
